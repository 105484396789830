/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DetailedDeal,
  PaginatedDealList,
} from '../models';
import {
    DetailedDealFromJSON,
    DetailedDealToJSON,
    PaginatedDealListFromJSON,
    PaginatedDealListToJSON,
} from '../models';

export interface DealCategoryListRequest {
    slug: string;
    page?: number;
}

export interface DealListRequest {
    page?: number;
}

export interface DealRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class DealApi extends runtime.BaseAPI {

    /**
     * A view that returns the latest deals.
     */
    async dealCategoryListRaw(requestParameters: DealCategoryListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDealList>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling dealCategoryList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v0/deal/category/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDealListFromJSON(jsonValue));
    }

    /**
     * A view that returns the latest deals.
     */
    async dealCategoryList(requestParameters: DealCategoryListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDealList> {
        const response = await this.dealCategoryListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A view that returns the latest deals.
     */
    async dealListRaw(requestParameters: DealListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDealList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v0/deal/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDealListFromJSON(jsonValue));
    }

    /**
     * A view that returns the latest deals.
     */
    async dealList(requestParameters: DealListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDealList> {
        const response = await this.dealListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A view that presents a single deal.
     */
    async dealRetrieveRaw(requestParameters: DealRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DetailedDeal>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dealRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v0/deal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedDealFromJSON(jsonValue));
    }

    /**
     * A view that presents a single deal.
     */
    async dealRetrieve(requestParameters: DealRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DetailedDeal> {
        const response = await this.dealRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
