import React, { useState } from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { RocketLaunch } from "@mui/icons-material";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

//drawer content used
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

// color palette
import { ThemeProvider, createTheme } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "40%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function FlexHeader() {
  const [open, setState] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setState(true);
  };

  const handleDrawerClose = () => {
    setState(false);
  };

  function goToCategory(slug: string) {
    navigate("/category/" + slug);
  }

  // TODO: fetch array from backend.
  // slug needs to be a valid category on backend
  const sections = [
    { title: "Electronics", slug: "electronics" },
    { title: "Credit Cards", slug: "credit-cards" },
    { title: "Fashion", slug: "fashion" },
    { title: "Home", slug: "tools-home-improvement" },
    { title: "Office", slug: "office-supplies" },
    { title: "Gift Cards", slug: "gift-cards" },
    // { title: "Travel", slug: "travel" },
  ];

  const funTheme = createTheme({
    // Comment out primary to restore original theme
    palette: {
      primary: {
        main: "#d00000", // Christmas and New Year Red
      },
    },
  });

  return (
    <ThemeProvider theme={funTheme}>
      <AppBar
        position="static"
        elevation={1}
        sx={{ mb: "6px", bgcolor: "primary.light", color: "primary" }}
      >
        <Toolbar>
          {/* hamburger icon shows the drawer on click */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              mr: 2,
              display: { xs: "block", sm: "none" },
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* Title */}
          <Typography variant="h5">
            <Link href="/" underline="none" color="inherit">
              {"Rocket Deals"}
              <RocketLaunch sx={{ mr: 1, ml: 1 }} />
            </Link>
          </Typography>

          {/* Desktop Navigation */}
          <Toolbar sx={{ display: { xs: "none", sm: "flex" } }}>
            {sections.map((section) => (
              <Link
                href={"/category/" + section.slug}
                color="inherit"
                underline="none"
                variant="body2"
                sx={{ p: 1, flexShrink: 0 }}
              >
                {section.title}
              </Link>
            ))}
          </Toolbar>

          {/* Search Field */}
          {/* <IconButton
            size="large"
            aria-label="search"
            color="inherit"
            sx={{ display: { xs: 'block', sm: 'none' } }}
          //onClick={}
          >
            <SearchIcon />
          </IconButton> */}
          {/* <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search> */}

          {/* The outside of the drawer */}
          <Drawer
            anchor="left" //from which side the drawer slides in
            variant="temporary" //if and how easily the drawer can be closed
            open={open} //if open is true, drawer is shown
            onClose={handleDrawerClose} //function that is called when the drawer should close
          >
            <Box>
              {/* The inside of the drawer */}
              {/* Mobile navigation */}
              <DrawerHeader>
                <Typography variant="subtitle2" color="primary">
                  Deals by Category
                </Typography>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </DrawerHeader>

              <List>
                {sections.map((section) => (
                  <ListItem key={section.title} disablePadding>
                    <ListItemButton onClick={handleDrawerClose}>
                      <ListItemText
                        primary={section.title}
                        onClick={() => {
                          goToCategory(section.slug);
                        }}
                      ></ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
