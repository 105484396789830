import Box from "@mui/material/Box";
import { render } from "@testing-library/react";
import { Deal, Image } from "./openapi";
interface DealImageProps {
  src: string | undefined;
  alt: string | undefined;
  // height: string | undefined;
  // width: string | undefined;
}

// Desktop is 132 max
// Mobile is 102 max
export default function DealImage(props: DealImageProps) {
  const mobileExtension = "=s102-rw";
  const desktopExtension = "=s132-rw";

  return (
    <div>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <img
          src={props.src + mobileExtension}
          alt={props.alt}
          width="auto"
          height="auto"
          style={{ maxHeight: "102", maxWidth: "102" }}
        ></img>
      </Box>

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <img
          src={props.src + desktopExtension}
          alt={props.alt}
          width="auto"
          height="auto"
          style={{ maxHeight: "132", maxWidth: "132" }}
        ></img>
      </Box>
    </div>
  );
}

export function dealImage(image?: Image) {
  if (typeof image === "undefined") {
    return <div></div>;
  } else {
    return (
      <div>
        <DealImage alt={image.title} src={image.url || undefined}></DealImage>
      </div>
    );
  }
}
