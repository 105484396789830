import * as React from "react";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Stack from "@mui/material/Stack";
// import { styled } from "@mui/material/styles";
import { Configuration, DealApi, PaginatedDealList } from "./openapi";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import DealItem from "./DealItem";
import { PaginatedDealListFromJSON } from "./openapi";
// import { marked } from "marked";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

interface DealStackProps {
  category_slug?: string;
}

export default function DealStack(props: DealStackProps) {
  const [cookies] = useCookies();

  // Declare a new state variable, which we'll call "count"
  const [deals, setDeals] = useState<PaginatedDealList | undefined>(undefined);

  useEffect(() => {
    // console.log("Props: " + props.category_slug);
    const configuration = new Configuration({
      basePath: process.env.REACT_APP_API_URL,
      headers: {
        "X-CSRFToken": cookies.csrftoken,
      },
    });

    if (process.env.NODE_ENV === "development") {
      setDeals(
        PaginatedDealListFromJSON({
          count: 7,
          next: null,
          previous: null,
          results: [
            {
              id: "c27a6927-303f-49ba-babb-e97b0324d97e",
              title:
                "OCuSOFT Lid Scrub Plus Eye Lid Cleanser, 30 Count - Now $14.80",
              description:
                '<p><a href="https://rocketdeals.today/go/e33fad14-b531-4a4c-8673-758cca332ba7">OCuSOFT Lid Scrub Plus Eye Lid Cleanser</a><br>\nSold and shipped by <a href="http://Walmart.com">Walmart.com</a><br>\nFree 90-day returns<br>\nFSA and HSA eligible</p>',
              images: [
                {
                  id: "8c56ac1e-5bb7-4527-a765-cdaef3648a25",
                  title: "Ocusoft Plus",
                  url: "https://lh3.googleusercontent.com/TuzjBHpqEULqc7GaLOsYYY-4tRpoSemdW8A1F6cfCu6Qaj9DNdRNb02SwK1Ly1xYsxuQSGCLXA6AKBNopnRvtqnINjYGQvq3KHJp",
                },
              ],
              created_at: "2022-12-20T17:30:01.671677Z",
              start_datetime: "2022-12-20T17:27:08Z",
              end_datetime: null,
            },
            {
              id: "1ae3a8c4-42bc-4db7-a805-56334c0d8393",
              title:
                "Merrell Moab 2 Mid Waterproof Hiking Boots - Women's - $57.83",
              description:
                '<p><a href="https://rocketdeals.today/go/6f48e3a1-68f2-46dd-8d2c-65816432b559">REDUCED PRICE</a>. You save 60%<br>\nShip FREE (minimum $50)</p>',
              images: [
                {
                  id: "bc972cd8-7988-4b37-a84d-5c732ab9fb4d",
                  title: "",
                  url: "https://lh3.googleusercontent.com/gKLroljwRNgZ45L1k2BvnLe8qPDz91GhcBHGYveOq2qw2fI2JM4pIcqism5Ioe-b5tqeOILDZcjz5pUm-5aOZVdMN1SYFQo3WflyZg",
                },
              ],
              created_at: "2022-12-19T17:52:11.910581Z",
              start_datetime: "2022-12-19T17:51:27Z",
              end_datetime: null,
            },
            {
              id: "c10bdd13-17bc-4468-9e84-fe5ba7bd7524",
              title: "Arctix Women's Convertible Trail Pant $20.18",
              description:
                '<p><a href="https://rocketdeals.today/go/01d20d00-b1c0-46ec-8903-8ccb4d6775df">Amazon Arctix Women&rsquo;s Convertible Trail Pant</a><br>\nFree Return + 5% Coupon</p>',
              images: [
                {
                  id: "684f42fe-a282-4afa-bfbb-77146443214f",
                  title: "",
                  url: "https://lh3.googleusercontent.com/Vd_QQCYQbQyeiU6P0QwK2ZyHypOkG0GDy66Ei_lZqKSdHr-jKM6SYVppRw8r4Lu12lRcJCF2V-sriXg0PxhPT50EYAfTaJ__IjdDFdI",
                },
              ],
              created_at: "2022-12-18T07:53:40.601961Z",
              start_datetime: "2022-12-18T07:56:37Z",
              end_datetime: null,
            },
            {
              id: "1af1d822-2763-4174-b773-a0361c0f972e",
              title: "Marriott: Resort Retreats - $50 Per Day Credit",
              description:
                '<p>A holiday resort retreat for the whole family. Book your holiday vacation and earn a $50 credit per night at one of our participating resorts on stays of four nights or more.</p>\n<p><a href="https://rocketdeals.today/go/525289a4-7333-4bd5-ba0d-49510ad96a5a"><strong>Explore this Offer</strong></a></p>\n<ul>\n<li>Book by Friday, March 31, 2023</li>\n<li>Select resorts in US, Canada, Caribbean &amp; LATAM only.</li>\n<li>Valid stay dates: October 26, 2022 - March 31, 2023</li>\n</ul>\n<p>Explore our collection throughout the U.S., Canada, Caribbean and Latin America and find the perfect retreat with a $50 credit per night. Offer varies by hotel.</p>',
              images: [
                {
                  id: "a17a8e71-217a-4cdc-988b-776d07044932",
                  title: "",
                  url: "https://lh3.googleusercontent.com/WaMSHxmAQnr-UAl2Iin6Qtlf4Wa5OKCIDIUrq3om4CaxMYPM-h9CC_bzcskdUMf63dSqasL6znuUEte1Mb4bR3elLtkAUHXiHj0ww4E",
                },
              ],
              created_at: "2022-12-15T06:54:11.686720Z",
              start_datetime: "2022-12-15T06:52:14Z",
              end_datetime: null,
            },
            {
              id: "4e4a3ea3-6c16-4a2f-90ca-eda85b58f920",
              title: "New Balance: Up to 70% off",
              description:
                '<p><a href="https://rocketdeals.today/go/43ee52f0-9081-4ffd-bcc2-f2d93311e60d">Joe&rsquo;s New Balance Outlet</a> has last minute shoppers&rsquo; sale<br>\nSave up to 70% off original prices</p>',
              images: [
                {
                  id: "62546259-ac81-498d-b0a6-b0c1441275cb",
                  title: "",
                  url: "https://lh3.googleusercontent.com/AhxsdG-rtev7jvUk3Ib5axp9aQ4E33HHqN4FLJqnHw2pUryjOsd9al8VEQDJC7OaQn23TB01eSigmHt1cMaLyd5YHwAZNr-fGSik6w",
                },
              ],
              created_at: "2022-12-15T06:41:36.457727Z",
              start_datetime: "2022-12-15T06:39:41Z",
              end_datetime: null,
            },
            {
              id: "63eb12af-ec03-48bc-8f55-2d03e7fc37a5",
              title: "GAP: Extra 50% Off Sale + Extra 10-20% Off",
              description:
                '<p>+ Free Shipping on All Order</p>\n<p><a href="https://rocketdeals.today/go/5150907d-4ae9-446a-9156-60755b6faa29">GAP</a> has an extra 50% Off sale with code &ldquo;GOSHOP&rdquo;, extra 10% off or 20% Off $100 regular priced items with code &ldquo;ADDON&rdquo;. Shipping is free with code &ldquo;SHIPFREE&rdquo;.</p>',
              images: [
                {
                  id: "20ec4485-65ab-4208-9c32-0723241cc159",
                  title: "",
                  url: "https://lh3.googleusercontent.com/OvoMwoxsje7_dnK3dPyghFKQTa2epz3gn0JmxH5tFyQhO3G7APDnyc4ilmMcVtjsU2vrRVo-P7Bx1Bu-IdtvQg1589AcT-gYJRu0",
                },
              ],
              created_at: "2022-12-15T06:29:44.337823Z",
              start_datetime: "2022-12-15T06:27:20Z",
              end_datetime: null,
            },
            {
              id: "7474fcfa-e7af-43dd-b447-74937ddad56e",
              title: '2022 Apple MacBook Air 13.6" Laptop (M2 8GB 256GB) $999',
              description:
                '<p>2022 Apple MacBook Air 13.6&rdquo; Laptop (M2 chip 8GB 256GB). </p>\n<p>Shipping is free.<br>\n- Amazon: <a href="https://rocketdeals.today/go/391d2366-b35d-4bdb-a98f-5e3d768d7115">Gray ‎MLXW3LL/A</a> $999<br>\n- Amazon: Midnight ‎MLY33LL/A $1149<br>\n- Amazon: Starlight MLY13LL/A (oos)<br>\n- Amazon: Silver ‎MLXY3LL/A $1149</p>',
              images: [
                {
                  id: "da5f096a-bfc9-4d51-8454-d7cf110c48e8",
                  title: "",
                  url: "https://lh3.googleusercontent.com/79mUC8IK34cqLtJuVlZn3-b0nPSLyChJTkcu8fidJENd7ytEUPhqFM1KA8hkE-Y7MNP7hGFSM79yK0tyB5p9_gK_zKBTZg3MUgTg",
                },
              ],
              created_at: "2022-12-15T06:11:11.412275Z",
              start_datetime: "2022-12-10T18:39:22Z",
              end_datetime: null,
            },
          ],
        })
      );
    }

    const apiClient = new DealApi(configuration);
    if (props.category_slug !== undefined) {
      apiClient
        .dealCategoryList({ slug: props.category_slug })
        .then((result) => {
          // console.log("Got back deals", result);
          setDeals(result);
        });
    } else {
      apiClient.dealList().then((result) => {
        // console.log("Got back deals", result);
        setDeals(result);
      });
    }
  }, [props.category_slug]);

  const dealItems = deals?.results?.map((deal) => <DealItem deal={deal} />);

  return <Box>{dealItems}</Box>
}
