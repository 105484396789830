import React from "react";
import "./App.css";
import Container from "@mui/material/Container";
import FlexHeader from "./Header2";
import Footer from "./Footer";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { dealImage } from "./DealImage";
import getRelativeTime from "./relativeTime";
import {
  Configuration,
  DealApi,
  DetailedDeal,
  DetailedDealFromJSON,
} from "./openapi";
import SEO from "./seo";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Stack from "@mui/material/Stack";

function isExpired(d1: Date) {
  const now = new Date();
  return (d1 as any) < (now as any);
}

export default function DealSingle() {
  let params = useParams();
  const [cookies] = useCookies();
  const [deal, setDeal] = useState<DetailedDeal | undefined>(undefined);
  const [invisible, setInvisible] = React.useState(false);

  useEffect(() => {
    // console.log("Props: " + props.category_slug);
    const configuration = new Configuration({
      basePath: process.env.REACT_APP_API_URL,
      headers: {
        "X-CSRFToken": cookies.csrftoken,
      },
    });

    if (process.env.NODE_ENV === "development") {
      setDeal(
        DetailedDealFromJSON({
          id: "0441da28-00a0-4919-9b15-5fbe7cdbbdf7",
          title:
            'AmazonBasics - Windshield Wiper Blades (Set of 2) - 22" / 17" Hook',
          description:
            '<p>Windshield wiper blades $5.47 (-75% off) on <a href="https://rocketdeals.today/go/d663fdc7-52c5-466e-9c3a-f947b0cca03b">Amazon</a></p>',
          created_at: "2022-12-22T19:44:48.852243Z",
          updated_at: "2022-12-22T19:46:32.905004Z",
          start_datetime: "2022-12-22T19:44:44Z",
          end_datetime: "2022-12-22T19:46:35Z",
          // end_datetime: null,
          // end_datetime: "2023-12-22T19:46:32.905004Z",
          images: [
            {
              id: "4a50da49-344d-44eb-8ba7-9f0aa18d05f2",
              title: "",
              url: "https://lh3.googleusercontent.com/qNYNmDBDBRKkMmOi7n8msR_LbD4TXyPVVWlIGSHngFp-Hmj9BWcoGZGMJKNaEQM1HC9LJ13IPL4TqfspCTAzaJY16egyaOGZBmiK44M",
            },
          ],
        })
      );
    }

    const apiClient = new DealApi(configuration);
    if (params.uuid !== undefined) {
      apiClient.dealRetrieve({ id: params.uuid }).then((result) => {
        setDeal(result);
      });
    } else {
      setDeal(undefined);
    }
  }, [params.uuid]);

  return (
    <Box>
      <SEO
        title={"Rocketdeals - " + deal?.title!}
        description={deal?.title!}
        name="name"
        type="deal"
        url={process.env.PUBLIC_URL + "/deal/" + deal?.id}
        image={deal?.images[0].url + "=s102-rw"}
      />
      <FlexHeader />

      <Container maxWidth="lg">
        {/* Disclaimer */}
        <Box sx={{ fontWeight: "fontWeightLight", mb: "30px" }}>
          <Typography fontSize={10} color="text.secondary" align="center">
            We review deals independently, and may earn comissions when you buy
            through links on rocketdeals.today.
          </Typography>
        </Box>

        <Grid container columnSpacing={2} sx={{ mb: "20px" }}>
          {/* Image Column */}
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {dealImage(deal?.images[0])}
          </Grid>

          {/* Text Column */}
          <Grid item xs={12} sm={8} md={9} lg={10}>
            <Typography variant="h6">{deal?.title}</Typography>

            <Typography variant="body2">
              <div
                dangerouslySetInnerHTML={{ __html: deal?.description! }}
              ></div>
            </Typography>

            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Typography
                fontSize={14}
                color="text.secondary"
                sx={{ mt: "6px" }}
              >
                {getRelativeTime(
                  deal?.startDatetime || new Date("1970-01-01T00:00:00Z")
                )}
              </Typography>

              <Chip
                label="Expired"
                color="error"
                size="small"
                variant="outlined"
                sx={{
                  display:
                    deal?.endDatetime && isExpired(deal?.endDatetime)
                      ? null
                      : "none",
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
}
