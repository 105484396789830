/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';

/**
 * 
 * @export
 * @interface DetailedDeal
 */
export interface DetailedDeal {
    /**
     * 
     * @type {string}
     * @memberof DetailedDeal
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDeal
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDeal
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof DetailedDeal
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DetailedDeal
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DetailedDeal
     */
    startDatetime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DetailedDeal
     */
    endDatetime?: Date | null;
    /**
     * 
     * @type {Array<Image>}
     * @memberof DetailedDeal
     */
    images: Array<Image>;
}

/**
 * Check if a given object implements the DetailedDeal interface.
 */
export function instanceOfDetailedDeal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "images" in value;

    return isInstance;
}

export function DetailedDealFromJSON(json: any): DetailedDeal {
    return DetailedDealFromJSONTyped(json, false);
}

export function DetailedDealFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedDeal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'startDatetime': !exists(json, 'start_datetime') ? undefined : (json['start_datetime'] === null ? null : new Date(json['start_datetime'])),
        'endDatetime': !exists(json, 'end_datetime') ? undefined : (json['end_datetime'] === null ? null : new Date(json['end_datetime'])),
        'images': ((json['images'] as Array<any>).map(ImageFromJSON)),
    };
}

export function DetailedDealToJSON(value?: DetailedDeal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'start_datetime': value.startDatetime === undefined ? undefined : (value.startDatetime === null ? null : value.startDatetime.toISOString()),
        'end_datetime': value.endDatetime === undefined ? undefined : (value.endDatetime === null ? null : value.endDatetime.toISOString()),
        'images': ((value.images as Array<any>).map(ImageToJSON)),
    };
}

