// Single Deal
import * as React from "react";
import { Deal, Image } from "./openapi";
import { dealImage } from "./DealImage";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Chip, Stack, Typography } from "@mui/material";
import getRelativeTime from "./relativeTime";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

interface DealProps {
  deal: Deal;
}

function isExpired(d1: Date) {
  const now = new Date();
  return ((d1 as any) < (now as any))
}

export default function DealItem(props: DealProps) {
  const navigate = useNavigate();

  return (
    <Box>
      <Grid container columnSpacing={2} sx={{ mb: "20px" }}>
        {/* Image Column */}
        <Grid item xs={5} sm={4} md={3} lg={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Link href={"/deal/" + props.deal.id} underline="none" color="inherit">
            {dealImage(props.deal.images[0])}
          </Link>
        </Grid>

        {/* Text Column */}
        <Grid item xs={7} sm={8} md={9} lg={10}>
          <Link href={"/deal/" + props.deal.id} underline="none" color="inherit">
            <Typography variant="h6">{props.deal.title}</Typography>
          </Link>

          <Typography
            variant="body2"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: props.deal.description! }}
            ></div>
          </Typography>

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography fontSize={14} color="text.secondary" sx={{ mt: "6px" }}>
              {getRelativeTime(props.deal?.startDatetime || new Date("1970-01-01T00:00:00Z"))}
            </Typography>
            <Chip label="Expired" color="error" size="small" variant="outlined"
              sx={{ display: props.deal?.endDatetime && isExpired(props.deal?.endDatetime) ? null : "none" }} />
          </Stack>

        </Grid>
      </Grid>
    </Box>
  );
}

{
  /* <div dangerouslySetInnerHTML={{ __html: deal.description! }} />
      {deal.images?.map((image) => (
        <DealImage title={image.title} image={image.image}></DealImage>
      ))} */
}

// const dealImage = (image?: Image) => (
// <DealImage title={image.title || ""} image={image.image} />

// const dealItems = deals?.results?.map((deal) => (
//   <DealItem deal={deal} />
//   // document.getElementById('markdown').innerHTML = marked.parse({deal.description});
// ));

// let dealImage =
// const dealItems = deals?.results?.map((deal) => (
//   <DealItem deal={deal} />
//   // document.getElementById('markdown').innerHTML = marked.parse({deal.description});
// ));
// const dealImage = (
//     <DealImage title={props.deal.images[0]} />
// )

{
  /* <Grid container spacing={0}>
        <Grid item xs={4}>
          {dealImage(props.deal.images[0])}
        </Grid>
        <Grid item xs={8}>
          <h5>{props.deal.title}</h5>
          <div dangerouslySetInnerHTML={{ __html: props.deal.description! }} />
        </Grid>
      </Grid> */
}

{
  /* <Grid container spacing={0}>
        <Grid item xs={4}>
          {dealImage(props.deal.images[0])}
        </Grid>
        <Grid item xs={8}>
          <h5>{props.deal.title}</h5>
          <div dangerouslySetInnerHTML={{ __html: props.deal.description! }} />
        </Grid>
      </Grid> */
}
