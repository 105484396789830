import React from "react";
import { Helmet } from "react-helmet-async";

interface SEOProps {
  title: string;
  description: string;
  name: string;
  type: string;
  url: string;
  image: string;
}

export default function SEO(props: SEOProps) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={props.type} />
      <meta property="og:url" content={props.url} />
      <meta property="og:image" content={props.image} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={props.name} />
      <meta name="twitter:card" content={props.type} />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
