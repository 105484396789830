import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";

function Copyright() {
  return (
    <Typography fontSize={10} color="text.secondary" align="center">
      {"Copyright © " + new Date().getFullYear() + " "}
      <Link color="inherit" href="/">
        Rocket Deals Today
      </Link>
    </Typography>
  );
}

function Tagline() {
  return (
    <Typography variant="subtitle1" align="center" gutterBottom>
      Bringing the best deals to you daily!
    </Typography>
  );
}

export default function Footer() {
  return (
    <Paper
      sx={{ position: "static", bottom: 0, left: 0, right: 0 }}
      elevation={0}
    >
      {/* <Container maxWidth="lg"> */}
      <Tagline />
      <Copyright />
      {/* </Container> */}
    </Paper>
  );
}
