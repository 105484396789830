import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import DealSingle from "./DealSingle";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  Link,
  useParams,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function ErrorBoundary() {
  // let error = useRouteError();
  // console.error(error);
  // Uncaught ReferenceError: path is not defined
  // return <div>Oops! No rocket deals here.</div>;
  return (
    // <Container maxWidth="lg">
    //   <Box sx={{ width: "80%" }}>
    <Typography variant="body1" component="h2" align="center">
      <div>
        Oops! No rocket deals here. <Link to="/">Back to deals</Link>
      </div>
    </Typography>
    //   </Box>
    // </Container>
  );
}

function External() {
  let { uuid } = useParams();
  window.location.href = process.env.REACT_APP_API_URL + "/go/" + uuid;
  return null;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // loader: rootLoader,
    // children: [
    //   {
    //     path: "team",
    //     element: <Team />,
    //     loader: teamLoader,
    //   },
    // ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/go/:uuid",
    element: <External />,
  },
  {
    path: "/deal/:uuid",
    element: <DealSingle />,
  },
  {
    path: "category/:slug",
    element: <App />,
  },
]);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* Allows cookie to be used throughout App */}
      <CookiesProvider>
        <RouterProvider router={router} />
      </CookiesProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
