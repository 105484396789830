import React from "react";
import "./App.css";
import Container from "@mui/material/Container";
import FlexHeader from "./Header2";
import Footer from "./Footer";
import DealStack from "./DealStack";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SEO from "./seo";
function App() {
  let params = useParams();

  return (
    <Box>
      <SEO
        title="Bringing you the best deals"
        description="rocketdeals.today - Best deals today! Save money and improve your personal finances."
        name={process.env.DOMAIN!}
        type="website"
        url={process.env.PUBLIC_URL}
        image={process.env.PUBLIC_URL + "/favicon.ico"}
      />
      <FlexHeader />
      <Container maxWidth="lg">
        {/* Disclaimer */}
        <Box sx={{ fontWeight: "fontWeightLight", mb: "30px" }}>
          <Typography fontSize={10} color="text.secondary" align="center">
            We review deals independently, and may earn comissions when you buy
            through links on rocketdeals.today.
          </Typography>
        </Box>

        <DealStack category_slug={params.slug}></DealStack>
      </Container>

      <Footer />
    </Box>
  );
}

export default App;
